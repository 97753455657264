.error-notification {
  display: none;
  width: calc(100vw - var(--spacing-s));
  max-width: 480px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--spacing-m) auto 0 auto;
  padding: var(--spacing-xs);
  background-color: var(--pastel-lighter-rose);
  box-shadow: 0 0 0 2px var(--error-01);
  border-radius: 4px;

  & .error-notification-message {
    margin-left: var(--spacing-s);
  }

  & .error-notification-close {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    left: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
}
