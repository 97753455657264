p {
  & a:visited,
  & a:active {
    color: var(--primary-cta);
  }

  & a:focus-visible {
    box-shadow: 0 0 10px 2px var(--secondary-03);
    outline: 0;
  }
}

.link {
  font-weight: 500;

  &:hover {
    color: var(--primary-colour) !important;
  }

  &:focus-visible {
    box-shadow: 0 0 10px 2px var(--secondary-03);
  }

  &:visited {
    color: var(--primary-cta);
  }
}

a {
  &:hover {
    color: var(--primary-colour);
  }
}
