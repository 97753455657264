.delivery-country-selection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .delivery-country-title {
    font-family: var(--handwritten-font-family);
    font-size: var(--font-size-xl);
    line-height: 40px;
    text-align: center;
    margin-bottom: var(--spacing-m);
    margin-top: var(--spacing-s);

    @media (--tablet) {
      font-size: var(--font-size-xxl);
      line-height: 48px;
      margin: var(--spacing-l) 0;
    }
  }

  & .delivery-country-form {
    width: calc(100vw - var(--spacing-s));
    max-width: 500px;
    margin: 0 12px;
    text-align: center;

    & .country-radio-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: var(--spacing-xs) 0;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & input[type=radio] {
        display: none;

        &:checked + label::before {
          background-color: white;
          border: 8px solid var(--secondary-08);
        }
      }

      & label {
        width: max-content;
        font-size: var(--font-size-s);
        line-height: 24px;
        margin-right: 12px;
        padding-left: 32px;
        position: relative;

        &::before {
          content: '';
          height: 24px;
          width: 24px;
          display: inline-block;
          border: 1px solid black;
          border-radius: 50%;
          vertical-align: middle;
          box-sizing: border-box;
          position: absolute;
          left: 0;
        }
      }

      & .country-radio-image {
        height: 24px;
        width: 36px;
        margin-right: 0;
        margin-left: auto;
        background-size: contain;
        flex-shrink: 0;
      }
    }

    & #delivery-country-confirm {
      margin-top: var(--spacing-l);
      margin-bottom: var(--spacing-s);
      max-width: 280px;
      text-transform: capitalize;
      font-family: var(--default-font-family);
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;

      @media (--tablet) {
        margin-top: var(--spacing-xl);
      }
    }
  }

  & .delivery-country-alert {
    visibility: hidden;
    display: none;
  }
}
