body {
  & .hidden {
    display: none !important;
  }

  &.logged-in .hide-logged-in {
    display: none !important;
  }

  &:not(.logged-in) .hide-logged-out {
    display: none !important;
  }

  &.complete .hide-complete {
    display: none !important;
  }

  &:not(.complete) .hide-incomplete {
    display: none !important;
  }

  & .main {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 50px;
    z-index: 0;

    @media (--desktop) {
      margin-top: 90px;
    }
  }

  & .top-nav,
  & .logo {
    transition: 300ms all ease;
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--primary-blueberry);
  }
}

body.slide-up {
  & .top-nav {
    transform: translateY(-100%);

    & .logo {
      transform: scale(0.1);
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
select,
textarea {
  font-family: inherit;
  color: var(--navy-01);
  border: none;
  border-bottom: 1px solid var(--navy-01);
  border-radius: 0;
  overflow: visible;
  outline: none;
  min-height: 44px;
  font-size: 1.8rem;
  background: var(--primary-white);
  padding: 10px 50px 3px 5px;
  appearance: none;
}

#discovery-body {
  & .main {
    text-align: center;
  }
}

.handwritten {
  font-family: var(--handwritten-font-family);
}

.christmas-font {
  font-family: var(--christmas-font-family);
  font-size: 32px;
  line-height: 32px;
  color: var(--navy-00);
}

.christmas-background {
  color: var(--christmas-red);
}
