footer.footer {
  text-align: left;
  position: relative;
  font-size: var(--font-size-s);
  background-color: var(--primary-blueberry-10);

  @media (--desktop) {
    font-size: var(--font-size-body);
    text-align: center;

    & .group {
      text-align: left;
    }
  }

  padding: 30px 10px;
  height: initial;
  width: 100%;
  bottom: 0;
  max-width: none;
  transition: all 0.3s;

  & .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: none !important;
    background-color: transparent !important;
    width: 100%;
  }

  & .navigation-content {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 25px 15px;

    & > * {
      max-width: 100%;
    }
  }

  & .group {
    display: inline-block;
    vertical-align: top;
    width: calc(50vw - 20px);
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 35%;

    & h5 {
      font-family: var(--display-font-family);
    }

    & ul {
      padding: 0;

      & li {
        list-style: none;
        display: block;
        padding-bottom: 10px;

        & a {
          color: var(--primary-black);
          text-decoration: none;
          font-size: inherit;

          &:hover {
            background: transparent;
          }
        }
      }
    }

    @media (--tablet) {
      width: 25%;
      text-align: center;
      padding-left: 0;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;

      & .wrapper {
        display: inline-block;
        text-align: left;
      }
    }
  }

  & .change-your-country {
    width: 400px;
    text-align: center;
    margin: 20px 0;

    & h5 {
      font-family: var(--display-font-family);
      color: var(--primary-blueberry);
    }

    & p {
      color: var(--primary-black);
    }

    & .country-selector {
      position: relative;

      @media (--tablet) {
        width: 300px;
        margin: 0 auto;
      }

      & select {
        width: 100%;
        padding: 10px 40px 10px 10px;
      }

      &::after {
        background-image: url('../svg/chevron-down.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        display: block;
        content: ' ';
        position: absolute;
        color: var(--primary-white);
        top: 10px;
        right: 10px;
        pointer-events: none;
      }
    }
  }

  & .social {
    margin: 30px 0;
    text-align: center;

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    & h5 {
      color: var(--primary-blueberry);
      font-family: var(--display-font-family);
    }

    & .links {
      & .link {
        font-size: 1em;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        margin-left: 40px;
        color: var(--primary-black);

        &:hover {
          background: transparent;
        }

        &.facebook::before {
          background-image: url('../svg/facebook-thin.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 44px;
          width: 44px;
          display: block;
          content: ' ';
        }

        &.twitter::before {
          background-image: url('../svg/twitter-thin.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 44px;
          width: 44px;
          display: block;
          content: ' ';
        }

        &.instagram::before {
          background-image: url('../svg/instagram-thin.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 44px;
          width: 44px;
          display: block;
          content: ' ';
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  & .copyright {
    font-size: 2.4rem;
    margin: 20px 0;
    text-align: center;

    & img {
      width: 109px;
    }

    & p {
      color: var(--primary-black);
      font-size: var(--font-size-body);
    }

    & .b-corp {
      margin-top: 20px;
      width: 70px;
    }

    & a {
      color: var(--primary-black);
    }
  }
}
