/* stylelint-disable no-descending-specificity */

#hamburger {
  display: none;
}

#hamburger:checked {
  & ~ .side-nav {
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.2);
    transform: translate3d(0, 0, 0);
    display: flex;
  }
}

nav .other-links {
  & .account {
    display: inline-block;
    white-space: nowrap;
  }
}

nav.top-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background-color: var(--primary-white);
  z-index: 3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @media (--desktop) {
    height: 90px;
  }

  & .navigation-content {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    & .links {
      display: none;
      font-size: 1.4rem;
      margin-left: 130px;
      z-index: 1;

      @media (--desktop) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: var(--spacing-xs);
      }

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-left: -5px;
        height: 100%;
      }

      & .link {
        color: var(--primary-colour);
        padding: 10px;
        height: auto;
        text-transform: none;
        position: relative;
        text-decoration: none !important;
        border-bottom: 1px solid transparent;
        margin-top: 4px;

        & .main-link {
          text-transform: none;
          text-decoration: none !important;
          color: var(--primary-colour);
          padding-bottom: 4px;
          font-size: inherit;
          font-weight: normal;

          &.active {
            border-bottom: 1px solid var(--primary-colour);
          }

          &:hover {
            background: transparent;
          }
        }

        & .text {
          color: var(--primary-colour);
        }

        & > span {
          display: block;
        }

        & > .spacer {
          font-weight: bold;
          visibility: hidden;
          height: 0;
        }

        &.home {
          text-align: center;
          font-size: 2rem;
          padding: 8px 9px;
          line-height: 1;

          & .text,
          & .spacer {
            display: none;
          }
        }

        &.has-subnav {
          padding: 0;
        }

        & a.text {
          color: inherit;
          text-decoration: none;
          padding: 10px 5px;
          display: inline-block;
        }

        & .subnav-outer {
          position: absolute;
          top: 38px;
          left: 0;
          padding-top: 8px;
        }

        & .subnav {
          padding: 0;
          margin: 0;
          white-space: nowrap;
          background: var(--primary-white);
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          display: none;

          & li {
            list-style: none;
            font-size: 1.4rem;
            margin: 10px 20px;

            &:first-child {
              margin: 20px 20px 10px 20px;
            }

            & a.sub-link {
              font-weight: normal;
              font-size: inherit;
              display: inline-block;
              padding-bottom: 4px;
              color: var(--primary-colour);
              text-decoration: none;
              border-bottom: 1px solid transparent;
            }

            & a.sub-link:hover,
            & a.sub-link.active {
              border-bottom: 1px solid var(--primary-colour);
              background: transparent;
            }
          }
        }

        &:hover {
          & .main-link {
            border-bottom: 1px solid var(--primary-colour);
          }

          & .subnav {
            display: block;
          }
        }
      }
    }

    & .logo-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;

      & .logo {
        position: relative;
        z-index: 2;
        display: inline-block;
        padding: var(--spacing-xs) 0;

        & a {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          &:hover {
            background: transparent;
          }
        }

        & img {
          height: 100%;
          width: 106px;
        }
      }

      @media (--desktop) {
        text-align: left;

        & .logo {
          padding: var(--spacing-m) var(--spacing-s) var(--spacing-xs) var(--spacing-xs);

          & img {
            height: 100%;
            width: 109px;
          }
        }
      }

      & .hamburger {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        left: 0;
        user-select: none;
        height: 50px;
        z-index: 4;
        text-align: center;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 50px;
        padding-left: 5px;

        @media (--desktop) {
          display: none;
        }

        & .icon {
          width: 30px;
          vertical-align: middle;
          display: inline-block;

          &::before {
            background-image: url('../svg/menu.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 20px;
            width: 30px;
            display: block;
            content: ' ';
          }
        }

        & .text {
          font-weight: normal;
          color: var(--primary-black);
          vertical-align: middle;
        }
      }
    }

    & .other-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
      min-width: 320px;
      line-height: 50px;
      height: 50px;
      margin-left: auto;

      @media (--tablet) {
        line-height: 90px;
        height: 90px;
      }

      & .promo-header {
        display: none;
      }

      & > * {
        line-height: 1.4;
        line-height: initial;
      }

      & .link {
        text-align: center;
        min-width: 120px;

        & > * {
          vertical-align: middle;
        }

        & .icon {
          margin-left: 5px;
          font-size: 3rem;
        }
      }

      & .nav-btn {
        background-color: transparent;
        padding: 0 8px;
        border: none;
        color: var(--primary-colour);
        text-decoration: none;

        & .icon {
          color: var(--primary-colour);
        }
      }

      & .account {
        display: none;

        @media (--desktop) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        & .link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 8px 24px;

          &:visited {
            color: var(--primary-text-link);
          }

          & .text {
            font-size: var(--font-size-xs);
            text-transform: capitalize;
            font-weight: normal;
            color: var(--primary-black);
          }

          & .user-login::before {
            background-image: url('../svg/user-transparent.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            display: block;
            content: ' ';
            font-size: 1.2em;
            padding: 0 15px;
          }

          &:hover {
            background: transparent;
          }
        }
      }

      & .country {
        padding: 0 5px;
        pointer-events: none;
        height: 20px;

        & .country-flag {
          width: 24px;
        }
      }

      & .country-selector {
        overflow: hidden;
        position: relative;
        width: 18px;
        min-width: 18px;
        max-width: 18px;
        height: 15px;
        margin-right: 10px;

        @media (--desktop) {
          margin: 0;
        }

        & select {
          border: none;
          width: 18px;
          height: 18px;
          padding: 0;

          &:focus-visible {
            box-shadow: 0 0 10px 2px var(--secondary-03);
          }
        }

        & .selector-arrow {
          position: absolute;
          width: 18px;
          height: 18px;
          top: -2px;
          right: 0;
          pointer-events: none;

          &::before {
            background-image: url('../svg/chevron-down.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            display: block;
            content: ' ';
            color: var(--primary-colour);
          }
        }
      }
    }
  }
}

nav.side-nav {
  position: fixed;
  display: none;
  top: 50px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-white);
  z-index: 2;
  line-height: 30px;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s;
  max-height: calc(100vh - 50px);
  overflow: auto;
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & .other-links {
    width: 100%;
    text-align: left;

    & .promo-header {
      display: none;
    }

    & .account {
      width: 100%;
      margin-top: 20px;

      & a.link {
        color: var(--primary-black);
      }

      & .text {
        display: inline-block;
      }

      & .user-login {
        display: inline-block;

        &::before {
          background-image: url('../svg/user-transparent.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 18px;
          width: 18px;
          display: block;
          content: ' ';
          font-size: 1.2em;
          padding: 0 15px;
        }
      }
    }
  }

  & .links {
    width: 100%;
    display: inline-block;
    text-align: left;
    padding-top: 20px;
  }

  & .spacer {
    display: none;
  }

  & .link {
    display: block;
    color: var(--primary-colour);
    font-size: 1.4rem;
    text-transform: none;
    padding: 8px 30px;
    text-decoration: none;

    & :hover {
      color: var(--primary-colour);
    }

    & .subnav-outer {
      & ul {
        padding: 0;
        margin: 0;
        font-size: 1.4rem;

        & li {
          list-style: none;
          padding: 10px 20px;
        }
      }
    }

    & a.main-link,
    & a.sub-link {
      text-decoration: none;
      color: var(--primary-colour);
      padding-bottom: 4px;
      font-weight: normal;

      &.active {
        border-bottom: 1px solid var(--primary-colour);
      }
    }
  }
}
