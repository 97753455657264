.tails-grid {
  --cols: 4;

  display: grid;
  column-gap: var(--spacing-s);
  row-gap: var(--spacing-xxs);
  grid-template-columns: repeat(var(--cols), minmax(0, 1fr));

  @media (--tablet) {
    --cols: 6;

    column-gap: var(--spacing-m);
  }

  @media (--desktop) {
    --cols: 12;
  }
}
