body.terms-and-conditions {
  & .col-single-wide {
    max-width: 700px !important;
    margin: auto;
    padding: 20px;
    text-align: left;
  }

  & .quick-nav {
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    padding: 15px;
    margin: 30px 0;

    & .in-page-nav {
      text-align: center;
      padding: 0;
      font-size: 0;

      & .btn {
        font-size: $font-size-body;
        width: 100%;
        margin: 4px;
        display: inline-block;

        & a {
          color: white;
        }
      }
    }
  }

  & .panel:first-child {
    margin-top: 20px;
  }
}
