/* screen sizes */
@custom-media --phone (min-width: 480px);
@custom-media --tablet (min-width: 768px);
@custom-media --desktop (min-width: 992px);

:root {
  --error-01: #a91910;
  --christmas-red: #b72336;
  --christmas-font-family: 'Nickainley', cursive;
  --button-font-size: calc(1rem * 1.8);
}
