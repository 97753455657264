.carousel {
  & .flickity-button {
    color: var(--secondary-03);
    background: none;
    background-color: transparent;
    border-radius: 0;
    height: 100%;

    &:active {
      opacity: 1;
    }

    &:hover {
      background: none;
    }

    &:focus {
      box-shadow: none;
    }

    &.flickity-prev-next-button.next {
      right: 0;
      background-color: transparent;
    }

    &.flickity-prev-next-button.previous {
      left: 0;
      background-color: transparent;
    }
  }

  & .flickity-page-dots {
    & .dot {
      opacity: 1;
      background: transparent;
      border: 1px solid var(--primary-black);

      &::before {
        display: none;
      }

      &.is-selected {
        background: var(--primary-black);
        transform: scale(1.2);
      }
    }
  }

  & .carousel-cell {
    counter-increment: carousel-cell;
    width: 100%;
    background-color: var(--primary-white);
  }
}

.discovery-flash-label {
  background: var(--primary-blueberry-10);
  margin: auto;
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  border-radius: 0;

  & label.modal-toggle {
    color: var(--primary-text-link);
    text-decoration: underline;
    cursor: pointer;
  }
}

.discovery-flash-modal {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (--tablet) {
    justify-content: center;
  }

  & .modal-checkbox {
    display: none;
  }

  & .modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: #000;
    width: 100%;
    z-index: 4;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  & .modal {
    transition: opacity 0.3s, transform 0.3s;
    background-color: var(--primary-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    transform: rotateX(90deg) scale(0.7);
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    margin: 10px;
    top: 10%;
    width: calc(100% - 20px);
    line-height: 1;
    z-index: 5;

    @media (--tablet) {
      width: 610px;
      margin: 0;
      top: 20%;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    & label.close {
      cursor: pointer;
      top: 15px;
      right: 15px;
      position: absolute;
      text-decoration: none;

      &::before {
        background-image: url('../svg/plus.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 25px;
        width: 25px;
        display: block;
        content: ' ';
        transform: rotate(45deg);
      }
    }

    & .modal-body {
      text-align: left;
      padding: 40px 10px 30px 10px;
      overflow-y: auto;
      max-height: 500px;

      @media (--tablet) {
        padding: 40px 30px 30px 30px;
      }
    }
  }

  & .modal-checkbox:checked ~ .modal {
    opacity: 1;
    transform: scale(1);
    pointer-events: initial;
  }

  & .modal-checkbox:checked ~ .modal-cover {
    opacity: 0.5;
    pointer-events: initial;
  }
}
